import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
import Product from './pages/Product';
import Admin from './pages/Admin';
import Cart from './pages/Cart';
import AdminLogin from './pages/AdminLogin';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import { AuthProvider, useAuth } from './AuthContext';
import DevControl from './pages/DevControl';
import Profile from './pages/Profile'
import Test from './pages/test'
import ResetPassword from './pages/ResetPassword'
import Manufacturing from './pages/Manufacturing'

function App() {
  return (
    <AuthProvider> {/* Wrap your entire app with AuthProvider */}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/products/:id/:title?" element={<Product />} />
          <Route path="/products" element={<Products />} />
          <Route path="/admin" element={<AdminOrLogin />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/policy" element={<Test />} />
          <Route path="/manufacturing" element={<Manufacturing />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

function AdminOrLogin() {
  // Use the AuthContext here to access admin status
  const { adminStatus } = useAuth();

  // If adminStatus is '1', render the Admin component; otherwise, redirect to AdminLogin
  return adminStatus === '1' ? <Admin /> : <Navigate to="/adminlogin" />;
}
