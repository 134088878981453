import React, { useEffect } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

import transknit from '../images/template.png'

const About = () => {

    useEffect(() => {
       
        
    
        window.scrollTo(0, 0); // Scroll to top when component mounts

    
    
      }, []);


    return (
        <div class=" bg-white">
            <div>
                <Header />
                <div class="container m-auto px-6 text-gray-100 md:px-12 xl:px-6 lg:py-16 pb-16 2xl:px-16">
                    <div class=" md:space-y-0 lg:flex space-x-4 lg:items-center lg:gap-12">
                        <div class="lg:w-4/12">
                            <img src={transknit} alt="image" loading="lazy" width="" height="" />
                        </div>
                        <div class="lg:w-8/12">
                        <div>
                            <h2 class="text-2xl text-gray-950 font-bold md:text-4xl">We Manufacture Your products</h2>
                            <p class="mt-6 text-gray-800">At Maven Nest, we take pride in manufacturing our products to ensure the highest quality and performance. Our commitment to excellence begins with a meticulous design process, where we leverage the latest technology and insights from top athletes to create equipment and apparel that meet the demands of today's sports enthusiasts. By controlling every aspect of production, we maintain strict quality standards that set our products apart.</p>
                            <p class="mt-4 text-gray-800">Our manufacturing facilities are equipped with state-of-the-art machinery and staffed by skilled professionals who share our passion for sports. From sourcing premium materials to implementing advanced manufacturing techniques, we ensure that every product bearing the Maven Nest name is built to last. Our rigorous testing procedures guarantee that our equipment can withstand the toughest conditions, providing reliability and durability you can count on.</p>
                        </div>

                        <div class="mt-10 border-2 border-gray-200 p-4 rounded -md">
                            <h2 class="text-2xl text-gray-950 font-bold md:text-2xl">Contact</h2>
                            <p class="mt-2 text-gray-800"><b>Manufacturer </b>Muhammad Tanveer</p>
                            <p class="mt-2 text-gray-800"><b>Whatsapp </b> +92 300 6108149</p>
                            <p class="mt-2 text-gray-800"><b>Email </b> themavennest22@gmail.com</p>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>
           
            <Footer />
        </div>
    );
}

export default About;